/** @define Nav */

.Nav-items {
  line-height: 1.625;
}

.Nav-items:not(:last-child) {
  margin-bottom: calc(.3125 * 4rem);
}

.Nav-link {
  border-bottom: 1px solid;
  /*font-family: "flanders-serif";*/
  font-weight: 500;
}

.Nav-link:not(.is-open) {
  border-bottom-color: transparent;
}

.Nav-link--icon {
  border: 0;
  display: block;
  height: 1.0625rem;
  width: 1.0625rem;
}

.Nav-icon {
  display: block;
  height: inherit;
  width: inherit;
}

@media (min-width: 50em) {

  .Nav-wrapper {
    display: flex;
    align-items: center;
  }

  .Nav-items--links {
    flex-grow: 1;
    flex-shrink: 1;
    line-height: 1.25;
  }

  .Nav-items:not(:last-child) {
    margin-bottom: 0;
    margin-right: calc(.3125 * 5rem);
  }

  .Nav-item {
    display: inline-block;
    vertical-align: top;
  }

  .Nav-item:not(:last-child) {
    margin-right: calc(.3125 * 5rem);
  }

}
