html {
  box-sizing: border-box;
  font-size: 100%;
  text-size-adjust: 100%;
  touch-action: manipulation;
}

html,
body {
  height: 100%;
}

* {
  background: transparent;
  border: 0;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

article,
aside,
figcaption,
figure,
footer,
header,
main,
nav,
section {
  display: block;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

button,
input,
select,
textarea {
  appearance: none;
  border-radius: 0;
  text-align: inherit;
}

em {
  font-style: italic;
}

iframe,
img {
  vertical-align: middle;
}

img {
  height: auto;
  max-width: 100%;
}

ol,
ul {
  list-style: none;
}

strong {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

[hidden] {
  display: none !important;
}

/* Forms */

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: .5;
}

::-moz-input-placeholder {
  color: inherit;
  opacity: .5;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .5;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}