.Continue {
  /*font-family: "flanders-serif";*/
  font-weight: 500;
  font-size: 1.1875rem;
  line-height: 1.4;
  text-align: center;
}

.Continue-link {
  display: inline-block;
  border: 2px solid #111;
  padding: calc(.3125 * 2rem) calc(.3125 * 4rem);
}

.Continue-link::after {
  content: "";
  display: inline-block;
  vertical-align: baseline;
  width: .8215rem;
  height: .8125rem;
  margin-left: calc(.3125 * 2rem);
  background-image: url("../images/icon-arrow-right.svg");
  background-repeat: no-repeat;
}