/* Flanders Sans */

@font-face {
  font-family: "flanders-sans";
  src: url("../fonts/flanders-sans-regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;

}

@font-face {
  font-family: "flanders-sans";
  src: url("../fonts/flanders-sans-medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "flanders-sans";
  src: url("../fonts/flanders-sans-bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}

/* Flanders Serif (removed) */

/* @font-face {
  font-family: "flanders-serif";
  src: url("../fonts/flanders-serif-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
} */

/* @font-face {
  font-family: "flanders-serif";
  src: url("../fonts/flanders-serif-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
} */

/* @font-face {
  font-family: "flanders-serif";
  src: url("../fonts/flanders-serif-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
} */
