.EventCompact {
  background-color: #fff;
}

.EventCompact-wrapper {
  position: relative;
}

.EventCompact-passed {
  position: absolute;
  z-index: 0;
  top: calc(.3125 * 3rem);
  left: calc(.3125 * 3rem);
}

.EventCompact-content {
  padding: calc(.3125 * 3rem);
}

.EventCompact-header {
  margin-bottom: calc(.3125 * 3rem);
}

.EventCompact-location,
.EventCompact-dates:not(:last-child) {
  margin-bottom: calc(.3125 * 1rem);
}

.EventCompact-title {
  font-size: 1.3125rem;
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: calc(.3125 * 1rem);
}

.EventCompact-dates,
.EventCompact-location,
.EventCompact-categories {
  /*font-family: "flanders-serif";*/
  font-weight: 500;
}

.EventCompact-location::before {
  content: "";
  display: inline-block;
  vertical-align: baseline;
  height: .8125rem;
  width: .8125rem;
  margin-right: .3125rem;
  background-image: url("../images/icon-location.svg");
  background-repeat: no-repeat;
}

@media (min-width: 50em) {

  .EventCompact-content {
    padding: calc(.3125 * 4rem);
  }

  .EventCompact-passed {
    top: calc(.3125 * 4rem);
    left: calc(.3125 * 4rem);
  }

  .EventCompact-header {
    margin-bottom: calc(.3125 * 5rem);
  }

  /* .EventCompact-location,
  .EventCompact-dates:not(:last-child) {
    margin-bottom: calc(.3125 * 1rem);
  } */

}