/** @define EventDates */

.EventDates-items {
  /* opera bug? */
  border: 1px solid transparent;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.EventDates-items:not(:last-child) {
  margin-bottom: calc(.3125 * 2rem);
}

/**
 * truncate dates
 * (based on line-height of 1.4)
 */
.EventDates-items--5.is-truncated {
  max-height: calc(5 * 1.4rem);
}

.EventDates-items--8.is-truncated {
  max-height: calc(8 * 1.4rem);
}

.EventDates-items.is-truncated::after {
  /* spread gradient over last three */
  background-image: linear-gradient(
    to top,
    rgba(255,255,255,1) .35rem,
    rgba(255,255,255,0) calc(3 * 1.4rem)
  );
  /* background: linear-gradient(to top, white, transparent); */
  content: "";
  height: 100%;
  left:0;
  position: absolute;
  top:0;
  width: 100%;
  z-index: 1;
  pointer-events: none;
}

.EventDates-more {
  font-weight: 500;
}

.EventDates-item--passed {
  opacity: .4;
}

.EventDates-date,
.EventDates-time {
    /*font-family: "flanders-serif";*/
    font-weight: 500;
}

.EventDates-date:not(:last-child)::after,
.EventDates-time:not(:last-child)::after {
  content: ", ";
}

.EventDates-date::before {
  content: "";
  display: inline-block;
  vertical-align: baseline;
  height: .8125rem;
  width: .8125rem;
  margin-right: .3125rem;
  background-image: url("../images/icon-calendar.svg");
  background-repeat: no-repeat;
}

.EventDates-link {
  border-bottom: 1px solid;
}
