.Content {
  padding-bottom: calc(.3125 * 5rem);
}

.Content--padded {
  padding-top: calc(.3125 * 5rem);
}

.Content--detail {
  padding-top: calc(.3125 * 5rem);
}

.Content-item:not(.Content-item--flickit) {
  padding-left: calc(.3125 * 3rem);
  padding-right: calc(.3125 * 3rem);
}

.Content-item:not(:last-child) {
  margin-bottom: calc(.3125 * 5rem);
}

.Content-item--page {
  background-color: #ffeb00;
  padding-bottom: calc(.3125 * 4rem);
  padding-top: calc(.3125 * 1rem);
}

@media (min-width: 50em) {

  .Content {
    padding-bottom: calc(.3125 * 10rem);
  }

  .Content--padded {
    padding-top: calc(.3125 * 10rem);
  }

  .Content--detail {
    padding-top: calc(.3125 * 10rem);
  }

  .Content-item:not(:last-child) {
    margin-bottom: calc(.3125 * 10rem);
  }

  .Content-item--page {
    padding-bottom: calc(.3125 * 8rem);
  }

}
