.EventList-wrapper {
  overflow: hidden;
}

.EventList-wrapper:not(:last-child) {
  margin-bottom: calc(.3125 * 5rem);
}

.EventList-wrapper,
.EventList-continue {
  margin-left: auto;
  margin-right: auto;
  max-width: 50rem;
}

.EventList-title {
  /*font-family: "flanders-serif";*/
  font-size: 1.1875rem;
  font-weight: 500;
  margin-bottom: calc(.3125 * 3rem);
}

.EventList-items {
  margin-bottom: calc(.3125 * -3rem);
}

.EventList-item {
  margin-bottom: calc(.3125 * 3rem);
}

.EventList-empty {
  font-weight: 500;
}

@media (min-width: 30em) {

  .EventList-title {
    font-size: 1.5rem;
    margin-bottom: calc(.3125 * 5rem);
  }

  .EventList-items {
    margin-right: calc(.3125 * -3rem)
  }

  .EventList-item {
    display: inline-block;
    margin-right: calc(.3125 * 3rem);
    vertical-align: top;
    width: calc(50% - calc(.3125 * 3rem));
  }

}

@media (min-width: 50em) {

  .EventList-wrapper:not(:last-child) {
    margin-bottom: calc(.3125 * 10rem);
  }

}