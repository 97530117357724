.BooktipList-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 50rem;
  overflow: hidden;
}

.BooktipList-title {
  /*font-family: "flanders-serif";*/
  font-size: 1.1875rem;
  font-weight: 500;
  margin-bottom: calc(.3125 * 3rem);
}

.BooktipList-items {
  margin-bottom: calc(.3125 * -3rem);
}

.BooktipList-item {
  margin-bottom: calc(.3125 * 3rem);
}

.BooktipList-empty {
  font-weight: 500;
}

@media (min-width: 30em) {

  .BooktipList-title {
    font-size: 1.5rem;
    margin-bottom: calc(.3125 * 5rem);
  }

}

@media (min-width: 50em) {

  .BooktipList-wrapper:not(:last-child) {
    margin-bottom: calc(.3125 * 10rem);
  }

}