.ArticleCompact {
  background-color: #fff;
}

.ArticleCompact-content {
  padding: calc(.3125 * 3rem);
}

.ArticleCompact-date::before {
  content: "";
  display: inline-block;
  vertical-align: baseline;
  height: .8125rem;
  width: .8125rem;
  margin-right: .3125rem;
  background-image: url("../images/icon-calendar.svg");
  background-repeat: no-repeat;
}

.ArticleCompact-header,
.ArticleCompact-excerpt,
.ArticleCompact-more:not(:last-child) {
  margin-bottom: calc(.3125 * 3rem);
}

.ArticleCompact-title {
  font-size: 1.3125rem;
  line-height: 1.25;
  font-weight: 500;
  margin-bottom: calc(.3125 * 1rem);
}

.ArticleCompact-date,
.ArticleCompact-categories {
  /*font-family: "flanders-serif";*/
  font-weight: 500;
}

@media (min-width: 30em) {

  .ArticleCompact-wrapper::after {
    clear: both;
    content: "";
    display: block;
  }

  .ArticleCompact-image,
  .ArticleCompact-content {
    float: left;
    width: 50%;
  }

}

@media (min-width: 50em) {

  .ArticleCompact-content {
    padding: calc(.3125 * 5rem);
  }

  .ArticleCompact-header,
  .ArticleCompact-excerpt,
  .ArticleCompact-more:not(:last-child) {
    margin-bottom: calc(.3125 * 5rem);
  }

}