.Embed {
  display: block;
}

.Embed-link {
  border: 0;
  display: block;
}

.Embed-ratio {
  background-color: #f0f0f0;
  padding-bottom: 100%;
  position: relative;
}

.Embed-ratio--16x7 {
  padding-bottom: calc(7/16 * 100%);
}

.Embed-ratio--3x2 {
  padding-bottom: calc(2/3 * 100%);
}

.Embed-ratio-2x3 {
  padding-bottom: calc(3/2 * 100%);
}

.Embed-ratio--4x3 {
  padding-bottom: calc(3/4 * 100%);
}

.Embed-ratio-3x4 {
  padding-bottom: calc(4/3 * 100%);
}

.Embed-ratio--5x4 {
  padding-bottom: calc(4/5 * 100%);
}

.Embed-ratio--4x5 {
  padding-bottom: calc(5/4 * 100%);
}

.Embed-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  bottom: 0;
  right: 0;
  margin: auto;
}

/* .Embed-content--fitwidth,
.Embed-content--fitheight {
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
} */

.Embed-content--fitheight {
  width: auto;
}

.Embed-content--fitwidth {
  height: auto;
}

.Embed-caption {
  font-size: .8125rem;
  padding: calc(.3125 * 3rem);
}

@media (min-width: 50em) {

  .Embed-caption {
    padding-left: calc(.3125 * 5rem);
    padding-right: calc(.3125 * 5rem);
  }

}