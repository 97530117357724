/** @define Flickit */

.Flickit-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 90rem;
}

.Flickit-items {
  position: relative;
  z-index: 1;
}

.Flickit-item {
  background-color: #ffffff;
  margin-right: 1rem;
  width: 100%;
}

.Flickit-item--feature {
  max-width: 50rem;
}

.Flickit-item--booktip {
  max-width: 50rem;
}

.Flickit-item--event {
  max-width: 24.5rem;
}

.no-js .Flickit-wrapper {
  overflow: scroll;
}

.no-js .Flickit-items {
  display: flex;
}

.no-js .Flickit-item {
  flex: 1 0 100%;
}
