@media not all and (min-width: 45em) {

  .FlexForm-item {
    /* border: 2px solid; */
    /* border-radius: 3px; */
  }

  .FlexForm-item:not(:last-child) {
    margin-bottom: calc(.3125 * 3rem);
  }

}

@media (min-width: 45em) {

  .FlexForm-items {
    /* border: 2px solid; */
    /* border-radius: 3px; */
    display: flex;
  }

  .FlexForm-item:not(:last-child) {
    flex: 1;
    margin-right: -2px;
    /* border-right: 2px solid; */
  }

  .FlexForm-item:last-child {
    /* This is unfortunately intertwined with the .Field-button width */
    width: calc(3rem + (.3125 * 8rem));
  }

}