.Article-wrapper {
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 50rem;
}

.Article-header,
.Article-content {
  padding: calc(.3125 * 4rem) calc(.3125 * 3rem);
}

.Article-header {
  line-height: 1.3;
}

.Article-title,
.Article-date,
.Article-categories {
  /*font-family: "flanders-serif";*/
  font-weight: 500;
}

.Article-title {
  font-size: 1.5rem;
  line-height: 1.15;
  margin-bottom: calc(.3125 * 1rem);
}

.Article-date::before {
  content: "";
  display: inline-block;
  vertical-align: baseline;
  height: .8125rem;
  width: .8125rem;
  margin-right: .3125rem;
  background-image: url("../images/icon-calendar.svg");
  background-repeat: no-repeat;
}

.Article-text:not(:last-child),
.Article-categories {
  margin-bottom: calc(.3125 * 4rem);
}

@media (min-width: 50em) {

  .Article-header,
  .Article-content {
    padding: calc(.3125 * 5rem);
  }

  .Article-text,
  .Article-categories {
    margin-bottom: calc(.3125 * 5rem) calc(.3125 * 6rem);
  }

  .Article-title {
    font-size: 2rem;
  }

}