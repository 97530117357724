/* flickity (customised, not standard flickity css) */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

.flickity-enabled.is-draggable {
  tap-highlight-color: transparent;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: grabbing;
}

.flickity-prev-next-button {
  background-color: #fffbcc;
  /* background-color: #ffeb00; */
  /* background-color: #ffffff; */
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: none;
  height: 2.75rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2.75rem;
  z-index: 3;
}

.flickity-prev-next-button:focus {
  outline: none;
}

.flickity-prev-next-button.previous {
  left: 2rem;
}

.flickity-prev-next-button.next {
  right: 2rem;
}

.flickity-prev-next-button:disabled {
  display: none;
}

.flickity-prev-next-button svg {
  fill: currentColor;
  height: 1.5rem;
  left: 0.625rem;
  position: absolute;
  top: 0.625rem;
  width: 1.5rem;
}

@media (min-width: 50em) {

  .flickity-prev-next-button {
    display: block;
  }

}

@media (min-width: 90em) {

  .flickity-viewport::before,
  .flickity-viewport::after {
    content: "";
    display: block;
    width: 10vw;
    max-width: 3.75rem;
    background-image: linear-gradient(
      90deg,
      hsl(55, 100%, 90%) 0%,
      hsla(55, 100%, 90%, 0.978) 9.5%,
      hsla(55, 100%, 90%, 0.925) 17.7%,
      hsla(55, 100%, 90%, 0.857) 24.7%,
      hsla(55, 100%, 90%, 0.781) 31%,
      hsla(55, 100%, 90%, 0.701) 36.8%,
      hsla(55, 100%, 90%, 0.62) 42.3%,
      hsla(55, 100%, 90%, 0.538) 47.6%,
      hsla(55, 100%, 90%, 0.455) 52.9%,
      hsla(55, 100%, 90%, 0.373) 58.2%,
      hsla(55, 100%, 90%, 0.291) 63.7%,
      hsla(55, 100%, 90%, 0.212) 69.5%,
      hsla(55, 100%, 90%, 0.138) 75.8%,
      hsla(55, 100%, 90%, 0.07) 82.9%,
      hsla(55, 100%, 90%, 0.019) 91.2%,
      hsla(55, 100%, 90%, 0) 100%
    );
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    pointer-events: none;
  }

  .flickity-viewport::before {
    left: 0;
  }

  .flickity-viewport::after {
    right: 0;
    transform: rotateY(180deg);
  }

}