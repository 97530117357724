.AspectRatio {
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
}

.AspectRatio-object {
  border: 0;
  height: 100%;
  left: 0;
  margin: auto;
  position: absolute;
  top: 0;
  width: 100%;
}
