.BooktipCompact {
  background-color: #fff;
}

.BooktipCompact-content {
  padding: calc(.3125 * 3rem);
}

.BooktipCompact-header,
.BooktipCompact-published,
.BooktipCompact-excerpt,
.BooktipCompact-more:not(:last-child) {
  margin-bottom: calc(.3125 * 3rem);
}

.BooktipCompact-title {
  font-size: 1.3125rem;
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: calc(.3125 * 1rem);
}

.BooktipCompact-author,
.BooktipCompact-categories {
  /*font-family: "flanders-serif";*/
  font-weight: 500;
}

.BooktipCompact-author::before {
  content: "";
  display: inline-block;
  vertical-align: baseline;
  margin-right: .3125rem;
  background-repeat: no-repeat;
  background-position: center;
}

.BooktipCompact-author::before {
  height: .6875rem;
  width: .6875rem;
  background-image: url("../images/icon-author.svg");
}

@media (min-width: 30em) {

  .BooktipCompact-wrapper::after {
    clear: both;
    content: "";
    display: block;
  }

  .BooktipCompact-image,
  .BooktipCompact-content {
    float: left;
    width: 50%;
  }

}

@media (min-width: 50em) {

  .BooktipCompact-content {
    padding: calc(.3125 * 5rem);
  }

  .BooktipCompact-header,
  .BooktipCompact-published,
  .BooktipCompact-excerpt,
  .BooktipCompact-more:not(:last-child) {
    margin-bottom: calc(.3125 * 5rem);
  }

}