.Feature-content {
  background-color: #fff;
  padding: calc(.3125 * 3rem);
}

.Feature-header,
.Feature-text,
.Feature-more {
  margin-bottom: calc(.3125 * 3rem);
}

.Feature-text {
  max-height: calc(1.4em * 4);
  overflow: hidden;
}

.Feature-location {
  margin-bottom: calc(.3125 * 1rem);
}

.Feature-title {
  font-size: 1.3125rem;
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: calc(.3125 * 1rem);
}

.Feature-date,
.Feature-location,
.Feature-categories {
  /*font-family: "flanders-serif";*/
  font-weight: 500;
}

.Feature-date::before {
  content: "";
  display: inline-block;
  vertical-align: baseline;
  height: .8125rem;
  width: .8125rem;
  margin-right: .3125rem;
  background-image: url("../images/icon-calendar.svg");
  background-repeat: no-repeat;
}

.Feature-location::before {
  content: "";
  display: inline-block;
  vertical-align: baseline;
  height: .8125rem;
  width: .8125rem;
  margin-right: .3125rem;
  background-image: url("../images/icon-location.svg");
  background-repeat: no-repeat;
}

@media not all and (min-width: 50em) {
  .Feature-column:not(:last-child) {
    margin-bottom: calc(.3125 * 3rem);
  }
}

@media (min-width: 50em) {

  .Feature-content {
    padding: calc(.3125 * 5rem);
  }

  .Feature-columns::after {
    clear: both;
    content: "";
    display: block;
  }

  .Feature-column  {
    float: left;
    width: 50%;
  }

}