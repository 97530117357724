/** @define Error */

.Error {
  padding: calc(.3125rem * 3rem);
}

.Error-logo {
  margin-bottom: calc(.3125rem * 3rem);
  max-width: calc(.3125 * 28rem);
  width: 100%;
}

@media (min-width: 50em) {

  .Error {
    padding-left: calc(.3125rem * 5rem);
    padding-right: calc(.3125rem * 5rem);
  }

  .Error-logo {
    margin-bottom: calc(.3125rem * 5rem);
  }

}