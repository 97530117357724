.Radio {
  position: relative;
  line-height: 2;
}

.Radio-label {
  display: block;
}

.Radio-input ~ .Radio-label {
  padding-left: calc(2rem + .3125 * 2rem);
  position: relative;
  text-align: left;
}

.Radio-input ~ .Radio-label::before,
.Radio-input ~ .Radio-label::after {
  border-radius: 50%;
  bottom: 0;
  content: "";
  margin-bottom: auto;
  margin-top: auto;
  position: absolute;
  top: 0;
}

.Radio-input ~ .Radio-label::before {
  border: 2px solid;
  height: 2rem;
  left: 0;
  width: 2rem;
}

.Radio-input ~ .Radio-label::after {
  width: 1rem;
  height: 1rem;
  left: .5rem;
  margin-left: auto;
  margin-right: auto;
}


.Radio-input:disabled ~ .Radio-label {
  color: #d5d5d5;
  cursor: default;
}

.Radio-input:checked ~ .Radio-label::after {
  background-color: currentColor;
}

.Radio-input {
  display: none;
}
