.Controls-item {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  margin: auto;
  z-index: 2;
}

.Controls-item--prev {
  left: 0;
}

.Controls-item--next {
  right: 0;
}

.Controls-link {
  display: block;
  padding-left: calc(.3125 * 3rem);
  padding-right: calc(.3125 * 3rem);
  width: calc(1.5rem + .3125 * 6rem);
  height: 100%;
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
  /* margin-left: .3125rem; */
  background-repeat: no-repeat;
  background-size: 1.5rem;
  background-position: center center;
  cursor: pointer;
}

@media (min-width: 60em) {
  .Controls-link {
    padding-left: calc(.3125 * 6rem);
    padding-right: calc(.3125 * 6rem);
    width: calc(1.5rem + .3125 * 12rem);
  }
}

.Controls-link--next {
  background-image: url("../images/icon-arrow-right.svg");
}

.Controls-link--prev {
  background-image: url("../images/icon-arrow-left.svg");
}

@media (min-width: 90rem) {
  .Controls-item--prev {
    left: calc((100vw - 90rem) / 2);
  }

  .Controls-item--next {
    right: calc((100vw - 90rem) / 2);
  }
}