.More {
    /*font-family: "flanders-serif";*/
    font-weight: 500;
}

.More-link::after {
  content: "";
  display: inline-block;
  vertical-align: baseline;
  height: .6875rem;
  width: .6875rem;
  margin-left: .3125rem;
  background-image: url("../images/icon-arrow-right.svg");
  background-repeat: no-repeat;
}