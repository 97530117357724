.ArticleList-wrapper {
  overflow: hidden;
}

.ArticleList-wrapper:not(:last-child) {
  margin-bottom: calc(.3125 * 5rem);
}

.ArticleList-wrapper,
.ArticleList-continue {
  margin-left: auto;
  margin-right: auto;
  max-width: 50rem;
}

.ArticleList-title {
  /*font-family: "flanders-serif";*/
  font-size: 1.1875rem;
  font-weight: 500;
  margin-bottom: calc(.3125 * 3rem);
}

.ArticleList-items {
  margin-bottom: calc(.3125 * -3rem);
}

.ArticleList-item {
  margin-bottom: calc(.3125 * 3rem);
}

.ArticleList-empty {
  font-weight: 500;
}

@media (min-width: 30em) {

  .ArticleList-title {
    font-size: 1.5rem;
    margin-bottom: calc(.3125 * 5rem);
  }

}

@media (min-width: 50em) {

  .ArticleList-wrapper:not(:last-child) {
    margin-bottom: calc(.3125 * 10rem);
  }

}