/** @define Footer */

.Field {
  display: block;
}

.Field-label {
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
  font-size: .9375rem;
}

.Field-label--optional::after {
  content: "optional";
  font-weight: normal;
  text-transform: lowercase;
  font-size: .875rem;
  color: #333;
  margin-left: calc(.3125 * 1rem);
}

.Field-label--optional[lang="nl"]::after {
  content: "optioneel";
}

.Field-help {
  font-size: .875rem;
  color: #333;
}

.Field-required,
.Field-error {
  color: #ee0000;
}

.Field-error {
  color: #ee0000;
  font-size: .9375rem;
}

.Field-label--hidden {
  display: none;
}

.Field-input {
  font-weight: 500;
}

.Field-input--file,
.Field-input--select {
  cursor: pointer;
}

.Field-input--select {
  position: relative;
  background-repeat: no-repeat;
  background-size: .75rem;
  background-position: calc(100% - .3125 * 3rem) center;
  background-image: url("../images/icon-arrow-select.svg");
}

.Field-input,
.Field-button {
  width: 100%;
}

.Field-input,
.Field-button {
  position: relative;
  border: 2px solid #111;
  padding: calc(.3125 * 2rem);
  line-height: 1.4;
  height: calc(1.4rem + (.3125 * 4rem) + 4px);
}

.Field-input--field {
  height: auto;
  max-height: calc(1.4rem + (.3125 * 4rem) + 4px);
}

.Field-input--textarea {
  min-height: calc(2.8rem + (.3125 * 4rem) + 4px);
  resize: vertical;
}

.Field-input:not(:last-child) {
  margin-bottom: calc(.3125 * 1rem);
}

.Field-error + .Field-input {
  border-color: #ee0000;
}

.Field-input:focus,
.Field-button:focus {
  outline: 0;
}

.Field-button {
  background-color: #111;
  color: #fff;
  cursor: pointer;
  text-indent: 200%;

  background-image: url("../images/icon-button.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.125rem;

  overflow: hidden;

  /* Probably need to find a more compatible solution: */
  /* mix-blend-mode: darken;  */
}

.Field-button--mailinglist {
  background-image: url("../images/icon-button-mailinglist.svg");
}

.Field-button--search {
  background-image: url("../images/icon-button-search.svg");
}

/**
 * Options
 */

.Field-option:first-child {
  margin-top: calc(.3125 * 1rem);
}

.Field-option:not(:last-child) {
  margin-bottom: calc(.3125 * 2rem);
}

/**
 * Upload
 */
.Field-input--upload {
    position: relative;
}

.Field-upload {
    position: absolute;
    z-index: 3;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.Field-uploadLabel {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
}

@media (min-width: 30em) {

  .Field-button {
    padding-left: calc(.3125 * 4rem);
    padding-right: calc(.3125 * 4rem);
  }

}


