.CategoryList-items {
  font-weight: 500;
}

.CategoryList-item:first-child::before {
  content: "";
  display: inline-block;
  vertical-align: baseline;
  height: .6875rem;
  width: .6875rem;
  margin-right: .3125rem;
  background-image: url("../images/icon-category.svg");
  background-repeat: no-repeat;   
}

.CategoryList-item {
  display: inline-block;
  vertical-align: baseline;
}

.CategoryList-item:not(:last-child)::after {
  content: ",";
}