.l-Default {
  background-color: #ffeb00;
  color: #111;
  font-family: "flanders-sans";
  font-weight: 400;
  line-height: 1.4;
  min-height: 100%;
  position: relative;
}

.l-Default-header,
.l-Default-footer {
  background-color: transparent;
  padding: calc(.3125 * 3rem);
}

.l-Default-admin {
  background-color: transparent;
  padding: calc(.3125 * 3rem);
}

.l-Default-content {
  background-color: #fffbcc;
}

@media (min-width: 50em) {

  .l-Default-header,
  .l-Default-footer {
    background-color: #ffeb00;
    padding-left: calc(.3125 * 5rem);
    padding-right: calc(.3125 * 5rem);
  }

  .l-Default-admin {
    padding-left: calc(.3125 * 5rem);
    padding-right: calc(.3125 * 5rem);
    position: absolute;
  }

  .l-Default-links {
    right: calc(.3125 * 5rem);
  }

}

@media (min-width: 50em) and (min-height: 40em) {

  /**
   * Compensate fixed header (.Header padding + .Header-logo height)
   */
  .l-Default {
    padding-top: calc(.3125 * 6rem + 3.5rem);
  }

  /**
   * Fix header
   */
  .l-Default-header {
    left: 0;
    position: fixed;
    z-index: 3;
    right: 0;
    top: 0;
    will-change: transform;
  }

}