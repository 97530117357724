.Passed {
  background-color: #ee5d6a;
  color: #fff;
  padding: calc(.3125 * 2rem) calc(.3125 * 3rem);
}

.Passed-label {
  font-weight: 500;
}

.Passed-label::before {
  content: "";
  display: inline-block;
  vertical-align: baseline;
  height: .5625rem;
  width: .625rem;
  margin-right: calc(.625 * 1rem);
  background-image: url("../images/icon-check.svg");
  background-repeat: no-repeat;
}