.Form-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 50rem;
  overflow: hidden;
}

.Form-wrapper--padded {
  padding-left: calc(.3125 * 3rem);
  padding-right: calc(.3125 * 3rem);
}

.Form-items {
  margin-bottom: calc(.3125 * -3rem);
  margin-right: calc(.3125 * -3rem);
}

.Form-item {
  margin-bottom: calc(.3125 * 3rem);
  margin-right: calc(.3125 * 3rem);
  width: calc(100% - .3125 * 3rem);
}

.Form-error {
  color: #ee0000;
}

.Form-error:not(:last-child) {
  margin-bottom: calc(.3125 * 3rem);
}

@media (min-width: 30em) {

  .Form-item--postcode,
  .Form-item--city,
  .Form-item--state,
  .Form-item--country {
    display: inline-block;
    vertical-align: bottom;
    width: calc(50% - .3125 * 3rem);
  }

}

@media (min-width: 50em) {

  .Form-wrapper--padded {
    padding-left: calc(.3125 * 5rem);
    padding-right: calc(.3125 * 5rem);
  }

}
