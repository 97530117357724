.Page {
  /* background-color: #fff799; */
  /* padding: calc(.3125 * 5rem) calc(.3125 * 3rem); */
}

.Page-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 50rem;
}

.Page-header {
  line-height: 1.3;
  margin-bottom: calc(.3125 * 3rem);
}

.Page-title,
.Page-subtitle,
.Page-translate {
  /*font-family: "flanders-serif";*/
  font-weight: 500;
}

.Page-translate {
  border-bottom: 1px solid;
}

.Page-title {
  font-size: 1.5rem;
}

.Page-subtitle {
  font-size: 1.1875rem;
}

.Page-text {
  font-weight: 500;
}

@media (min-width: 50em) {

  .Page-title {
    font-size: 2rem;
  }

  .Page-subtitle {
    font-size: 1.3125rem;
  }

  .Page-text {
    font-size: 1.125rem;
  }

}