.EventLocation {
  font-weight: 500;
  position: relative;
  padding-left: 1rem;
}

.EventLocation::before {
  content: "";
  display: inline-block;
  height: .8125rem;
  width: .625rem;
  background-image: url("../images/icon-location.svg");
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: .25rem;
}

.EventLocation-link {
  border-bottom: 1px solid;
}

.EventLocation-address {
  display: block;
}
