/** @define Logo */

.Logo {
  width: 100%;
}

.Logo-ratio {
  padding-bottom: calc(100% * 98 / 238);
  position: relative;
}

.Logo-content {
  bottom: 0;
  fill: currentColor;
  left: 0;
  max-height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}