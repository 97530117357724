.Text a {
  border-bottom: 1px solid;
}

.Text h1,
.Text h2,
.Text h3,
.Text h4,
.Text h5,
.Text h6,
.Text strong {
  font-weight: 500;
}

.Text ul {
    list-style: square;
    padding-left: calc(.3125 * 4rem);
}

.Text h1:not(:last-child),
.Text h2:not(:last-child),
.Text h3:not(:last-child),
.Text h4:not(:last-child),
.Text h5:not(:last-child),
.Text h6:not(:last-child),
.Text figure:not(:last-child),
.Text p:not(:last-child),
.Text ul:not(:last-child),
.Text ol:not(:last-child),
.Text div:not(:last-child) {
  /* margin-bottom: calc(.3125 * 3rem); */
  margin-bottom: 1.4em;
}
