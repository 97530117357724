.Share-wrapper {
  overflow: hidden;
}

.Share-items {
  margin-right: -1rem;
}

.Share-item {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
}

.Share-link {
  display: block;
}

.Share-icon {
  display: block;
  margin: auto;
  width: 1.5rem;
  height: 1.5rem;
}