/** @define Info */

.Info-title {
  /*font-family: "flanders-serif";*/
  font-weight: 500;
  margin-bottom: calc(.3125 * 1rem);
}

.Info-wrapper {
  overflow: hidden;
}

.Info-sections {
  margin-bottom: calc(.3125 * -5rem);
}

.Info-section {
  margin-bottom: calc(.3125 * 5rem);
}

.Info-text {
  font-size: .9375rem;
}


@media (min-width: 70em) {

  .Info-sections {
    margin-right: calc(.3125 * -5rem);
  }

  .Info-sections::after {
    clear: both;
    content: "";
    display: block;
  }

  .Info-section {
    float: left;
    margin-right: calc(.3125 * 5rem);
    width: calc((100% / 3) - (.3125 * 5rem));
  }

}
