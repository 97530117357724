.Mailinglist-wrapper {
  padding: calc(.3125 * 3rem);
}

.Mailinglist-wrapper {
  background-color: #ffeb00;
  margin-left: auto;
  margin-right: auto;
  max-width: 36rem;
}

.Mailinglist-title {
  /*font-family: "flanders-serif";*/
  font-size: 1.1875rem;
  font-weight: 500;
}

.Mailinglist-title,
.Mailinglist-form {
  margin-bottom: calc(.3125 * 3rem);
}

@media (min-width: 50em) {

  .Mailinglist-wrapper {
    padding: calc(.3125 * 5rem);
  }

  .Mailinglist-title {
    font-size: 1.3125rem;
  }

}