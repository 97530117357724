.Booktip-wrapper {
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 50rem;
}

.Booktip-content {
  padding: calc(.3125 * 4rem) calc(.3125 * 3rem);
}

.Booktip-header {
  line-height: 1.3;
}

.Booktip-title,
.Booktip-author,
.Booktip-categories {
  /*font-family: "flanders-serif";*/
  font-weight: 500;
}

.Booktip-title {
  font-size: 1.5rem;
  line-height: 1.15;
  margin-bottom: calc(.3125 * 1rem);
}

.Booktip-author::before {
  content: "";
  display: inline-block;
  vertical-align: baseline;
  margin-right: .3125rem;
  background-repeat: no-repeat;
  background-position: center;
}

.Booktip-author::before {
  height: .6875rem;
  width: .6875rem;
  background-image: url("../images/icon-author.svg");
}


.Booktip-header,
.Booktip-published,
.Booktip-text,
.Booktip-categories {
  margin-bottom: calc(.3125 * 4rem);
}

@media (min-width: 50em) {

  .Booktip-content {
    padding: calc(.3125 * 6rem) calc(.3125 * 5rem);
  }

  .Booktip-header,
  .Booktip-published,
  .Booktip-text,
  .Booktip-categories {
    margin-bottom: calc(.3125 * 6rem);
  }

  .Booktip-title {
    font-size: 2rem;
  }

}