.EmbedList-wrapper {
  overflow: hidden;
}

.EmbedList-items {
  margin-bottom: calc(.3125 * -6rem)
}

.EmbedList-item {
  margin-bottom: calc(.3125 * 6rem);
}