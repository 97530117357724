/* not used yet */

.Upload {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.Upload-input {
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.Upload-input:focus {
  outline: 0;
}

.Upload-label {
  background-color: #111111;
  color: #fffbcc;
  cursor: pointer;
  display: block;
  height: 100%;
  line-height: 1.4;
  overflow: hidden;
  padding: calc(.3125 * 2rem);
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  z-index: 2;
}