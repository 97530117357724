.Related {
  padding-left: calc(.3125 * 3rem);
  padding-right: calc(.3125 * 3rem);
}

.Related-title {
  /*font-family: "flanders-serif";*/
  font-size: 1.1875rem;
  font-weight: 500;
  margin-bottom: calc(.3125 * 3rem);
  margin-left: auto;
  margin-right: auto;
  max-width: 50rem;
}

.Related-content {
  margin-left: calc(.3125 * -3rem);
  margin-right: calc(.3125 * -3rem);
}

@media (min-width: 30em) {

  .Related-title {
    font-size: 1.5rem;
    margin-bottom: calc(.3125 * 5rem);
  }

}
