.Formset {

}

.Formset-title {
    font-weight: 500;
    margin-bottom: calc(.3125 * 3rem);
    text-transform: uppercase;
}

.Formset-items {
    background-color: #ffeb00;
    padding: .9375rem;
}

.Formset-items:first-child .Formset-item--delete {
    display: none;
}

.Formset-items,
.Formset-item:not(:last-child) {
  margin-bottom: calc(.3125 * 3rem);
}

.Formset-link {
    font-size: .9375rem;
    border-bottom: 1px solid;
    cursor: pointer;
}

.Formset-link:focus {
    outline: 0;
}

@media (min-width: 40em) {

    .Formset-wrapper {
        overflow: hidden;
    }

    .Formset-items {
        margin-right: calc(.3125 * -3rem);
    }

    .Formset-item {
        display: inline-block;
        width: calc((100% / 3) - (.3125 * 3rem));
        margin-right: calc(.3125 * 3rem);
    }

    .Formset-item:not(:last-child) {
        margin-bottom: calc(.3125 * 1rem);
    }

}