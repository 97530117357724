/** @define Pagination */

.Pagination {
  /* background: tomato; */
}

.Pagination-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 50rem;
  overflow: hidden;
}

@media not all and (min-width: 45em) {

  .Pagination-item:not(:last-child) {
    margin-bottom: calc(.3125 * 3rem);
  }

}

.Pagination-link {
  /*font-family: "flanders-serif";*/
  font-weight: 500;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 1.5rem;
  text-indent: 150%;
  white-space: nowrap;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  padding: calc(.3125 * 2rem);
}

.Pagination-link--next {
  background-image: url("../images/icon-arrow-right.svg");
}

.Pagination-link--prev {
  background-image: url("../images/icon-arrow-left.svg");
}

/* @media (min-width: 45em) { */

  .Pagination-items::after {
    content: "";
    clear: both;
    display: block;
  }

  .Pagination-item {
    width: calc(100% / 2);
  }

  .Pagination-item--prev {
    float: left;
  }

  .Pagination-item--next {
    float: right;
    text-align: right;
  }

/* } */