/** @define Header */

.Header-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 90rem;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.Header-toggle {
  display: none;
  height: 2.75rem;
  margin-left: auto;
  padding: .75rem;
  width: 2.75rem;
}

.js .Header-toggle {
  display: block;
}

.Header-nav {
  flex: 1 0 100%;
  margin-top: calc(.3125 * 3rem);
}

.js .Header-nav[data-hidden="true"] {
  display: none;
}

.Header-logo {
  width: 8.5rem;
}

@media (min-width: 50em) {

  .Header-wrapper {
    flex-wrap: nowrap;
    position: relative;
  }

  .Header-logo {
    order: 3;
    flex-basis: 8.5rem;
    flex-shrink: 0;
    margin-left: auto;
  }

  .Header-nav {
    flex: 1;
    margin-right: calc(.3125 * 5rem);
    margin-top: 0;
    order: 1;
  }

  .Header-nav {
    display: block !important;
  }

  .Header-toggle {
    display: none !important;
  }

}