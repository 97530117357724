.Event-wrapper {
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 50rem;
}

.Event-header,
.Event-content,
.Event-sections {
  padding: calc(.3125 * 4rem) calc(.3125 * 3rem);
}

.Event-header {
  line-height: 1.3;
}

.Event-title {
  font-size: 1.5rem;
  line-height: 1.15;
  margin-bottom: calc(.3125 * 1rem);
}

.Event-title,
.Event-subtitle {
  /*font-family: "flanders-serif";*/
  font-weight: 500;
}

.Event-section:not(:last-child),
.Event-subsection:not(:last-child) {
  margin-bottom: calc(.3125 * 4rem);
}

@media (min-width: 50em) {

  .Event-header,
  .Event-content,
  .Event-sections {
    padding: calc(.3125 * 6rem) calc(.3125 * 5rem);
  }

  .Event-section:not(:last-child) {
    margin-bottom: calc(.3125 * 6rem);
  }

  .Event-subsection {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - .3125 * 3rem);
  }

  .Event-subsection:not(:last-child) {
    margin-bottom: 0;
    margin-right: calc(.3125 * 6rem);
  }

  .Event-title {
    font-size: 2rem;
  }

}